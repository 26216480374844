import styled from "astroturf/index"
import React from "react"
import { IconFacebook } from "../../../icon/iconHeader/iconFacebook"
import { IconLinkedin } from "../../../icon/iconHeader/iconLinkedin"
import { IconVk } from "../../../icon/iconHeader/iconVk"

export const SocialNetwork = () => {
  return (
    <Container>
      <SocialLink
        i={"facebook"}
        target="_blank"
        href="http://www.facebook.com/dextechnology"
        rel="noopener noreferrer"
        aria-label="facebook"
      >
        <IconFacebook />
      </SocialLink>

      <SocialLink
        i={"linkedin"}
        target="_blank"
        href="http://www.linkedin.com/company/dextechnology"
        rel="noopener noreferrer"
        aria-label="linkedin"
      >
        <IconLinkedin />
      </SocialLink>
      <SocialLink
        i={"vk"}
        target="_blank"
        href="http://www.linkedin.com/company/dextechnology"
        rel="noopener noreferrer"
        aria-label="linkedin"
      >
        <IconVk />
      </SocialLink>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 20px;

  @media (max-width: 1199px) {
    display: none;
  }
`

const SocialLink = styled.a`
  margin: 0.1em;
  cursor: pointer;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3 ease-in-out;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px transparent, 0 2px 3px transparent;

  &:hover {
    background: white;
    path {
      fill: #000000;
    }
  }
`
