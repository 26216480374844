import React from "react"
import styled from "astroturf/index"
import { VideoBlock } from "./BlocksElement/VideoBlock"
import { IconArrowRigth } from "../../icon/iconArrowRigth"
import dDayVideo from "../../video/dDayVideo.mp4"
import { SocialNetwork } from "./BlocksElement/SocialNetwork"
import { ImageBlock } from "./BlocksElement/ImageBlock"
import { GatsbyDDayBackgroundVideoImg } from "../../images/activity/GatsbyDDayBackgroundVideoImg"
import { GatsbyDDayImg1 } from "../../images/activity/GatsbyDDayImg1"
import { GatsbyDDayImg2 } from "../../images/activity/GatsbyDDayImg2"
import { GatsbyDDayImg3 } from "../../images/activity/GatsbyDDayImg3"

export const DDayBlock = () => {
  return (
    <Container id={"section_1"}>
      <VideoBlock video={dDayVideo} builtIn={true}>
        <GatsbyDDayBackgroundVideoImg />
      </VideoBlock>
      <ContainerText>
        <SocialNetwork />
        <div className={"container"}>
          <Title>D-day</Title>
          <Description>
            Команда Dex организовала самую крупную ИТ-конференцию в регионе. Более 700 участников, 30 спикеров из
            Microsoft, Яндекса, Mail.ru, Rambler, Agima, 65apps. Получили награду "Открытие года Tagline Awards".
          </Description>
          <ImageBlock>
            <GatsbyDDayImg1 />
            <GatsbyDDayImg2 />
            <GatsbyDDayImg3 />
          </ImageBlock>
          <Link href={"https://day.dex-it.ru/"} aria-label={"Dex"}>
            Подробнее <IconArrowRigth />
          </Link>
        </div>
      </ContainerText>
    </Container>
  )
}

const Container = styled.div`
  padding-top: 50px;

  height: 100vh;
  overflow: hidden;

  padding-bottom: 66px;
  background: #423ab6;
  position: relative;

  @media (max-width: 1199px) {
    padding-top: 0;
    height: auto;
  }
  @media (max-width: 600px) {
    padding-top: 0;
  }
`

const ContainerText = styled.div`
  display: flex;
  @media screen and (min-width: 2001px) {
    margin-top: 35px;
  }
`

const Title = styled.div`
  font-family: "Gilroy", sans-serif;
  font-size: 90px;
  font-weight: bold;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin: 30px 0;

  @media screen and (max-width: 1599px) {
    font-size: 60px;
  }

  @media (max-height: 830px) {
    font-size: 40px;
    margin: 16px 0;
  }

  @media (max-width: 767px) {
    font-size: 30px;
  }
`

const Description = styled.div`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  color: #ffffff;
  margin-bottom: 60px;
  max-width: 540px;

  @media (max-height: 830px) {
    font-size: 16px;
    margin-bottom: 30px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
`

const Link = styled.a`
  font-family: "Gilroy", sans-serif;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  svg {
    margin-left: 30px;
    transition: 0.1s ease-in-out;
  }

  &:hover {
    > svg {
      transform: translateX(10px);
    }
  }

  @media (max-width: 1199px) {
    margin-top: 25px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
`
