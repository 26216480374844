import React from "react"

export const IconArrowRigth = ({ color, width, height }) => {
  return <svg width={width ? width : "62"}
              height={height ? height : "23"}
              viewBox="0 0 62 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 11.5H61M61 11.5L48.5 1M61 11.5L48.5 22"
      stroke={color ? color : "white"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
}
