import React from "react"
import styled from "astroturf/index"
import { VideoBlock } from "./BlocksElement/VideoBlock"
import { IconArrowRigth } from "../../icon/iconArrowRigth"
import runItDexVideo from "../../video/runItVideo.mp4"
import { SocialNetwork } from "./BlocksElement/SocialNetwork"
import { ImageBlock } from "./BlocksElement/ImageBlock"
import { GatsbyRunITBackgroundVideoImg } from "../../images/activity/GatsbyRunITBackgroundVideoImg"
import { GatsbyRunITImg2 } from "../../images/activity/GatsbyRunITImg2"
import { GatsbyRunITImg1 } from "../../images/activity/GatsbyRunITImg1"
import { GatsbyRunITImg3 } from "../../images/activity/GatsbyRunITImg3"

export const ZaBegBlock = () => {
  return (
    <Container id={"section_2"}>
      <VideoBlock video={runItDexVideo} builtIn={true}>
        <GatsbyRunITBackgroundVideoImg />
      </VideoBlock>
      <ContainerText>
        <SocialNetwork />
        <div className={"container"}>
          <Title>iT ЗА!БЕГ</Title>
          <Description>
            Мы за здоровый образ жизни! Каждое лето в парке организуем ИТ марафон. С нами бегут более 200 ИТишников от
            мала до велика
          </Description>
          <ImageBlock>
            <GatsbyRunITImg1 />
            <GatsbyRunITImg2 />
            <GatsbyRunITImg3 />
          </ImageBlock>
          <Link href={"https://run.dex-it.ru/"} aria-label={"Dex"}>
            Подробнее <IconArrowRigth />
          </Link>
        </div>
      </ContainerText>
    </Container>
  )
}

const Container = styled.div`
  padding-top: 50px;

  height: 100vh;
  overflow: hidden;

  padding-bottom: 66px;
  background: #446d00;
  position: relative;

  @media (max-width: 1199px) {
    padding-top: 0;
    height: auto;
  }
`

const ContainerText = styled.div`
  display: flex;
  @media screen and (min-width: 2001px) {
    margin-top: 35px;
  }
`

const Title = styled.div`
  font-family: "Gilroy", sans-serif;
  font-size: 90px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin: 30px 0;

  @media screen and (max-width: 1599px) {
    font-size: 60px;
  }

  @media (max-height: 830px) {
    font-size: 40px;
    margin: 16px 0;
  }

  @media (max-width: 767px) {
    font-size: 30px;
  }
`

const Description = styled.div`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  color: #ffffff;
  margin-bottom: 60px;
  max-width: 540px;

  @media (max-height: 830px) {
    font-size: 16px;
    margin-bottom: 30px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
`

const Link = styled.a`
  font-family: "Gilroy", sans-serif;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  svg {
    margin-left: 30px;
    transition: 0.1s ease-in-out;
  }

  &:hover {
    > svg {
      transform: translateX(10px);
    }
  }

  @media (max-width: 1199px) {
    margin-top: 25px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
`
