import React from "react"
import styled from "astroturf/index"
import { IconArrowRigth } from "../../icon/iconArrowRigth"
import { SocialNetwork } from "./BlocksElement/SocialNetwork"
import { ImageBlock } from "./BlocksElement/ImageBlock"
import { GatsbyFootballBackgroundVideoImg } from "../../images/activity/GatsbyFootballBackgroundVideoImg"
import { GatsbyFootballImg1 } from "../../images/activity/GatsbyFootballImg1"
import { GatsbyFootballImg2 } from "../../images/activity/GatsbyFootballImg2"
import { GatsbyFootballImg3 } from "../../images/activity/GatsbyFootballImg3"

export const MiniFootballBlock = ({goTo}) => {

  return (
    <Container id={"section_4"}>
      <ImgContainer>
        <GatsbyFootballBackgroundVideoImg />
      </ImgContainer>
      <ContainerText>
        <SocialNetwork />
        <div className={"container"}>
          <Title>Мини-футбол</Title>
          <Description>
            Футбольная команда Dex участвует в чемпионате по мини-футболу. В
            2019 году наша команда заняла первое место в Лиги С!
          </Description>
          <ImageBlock>
            <GatsbyFootballImg1 />
            <GatsbyFootballImg2 />
            <GatsbyFootballImg3 />
          </ImageBlock>
          <Link onClick={()=>goTo(4)}>
            Подробнее <IconArrowRigth />
          </Link>
        </div>
      </ContainerText>
    </Container>
  )
}

const Container = styled.div`
  padding-top: 50px;

  height: 100vh;
  overflow: hidden;

  padding-bottom: 66px;
  background: #446e4c;
  position: relative;

  @media (max-width: 1199px) {
    padding-top: 0;
    height: auto;
  }
`

const ContainerText = styled.div`
  display: flex;
  @media screen and (min-width: 2001px) {
    margin-top: 35px;
  }
`

const Title = styled.div`
  font-family: "Gilroy", sans-serif;
  font-size: 90px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin: 30px 0;

  @media (max-width: 1599px) {
    font-size: 60px;
  }

  @media (max-height: 830px) {
    font-size: 40px;
    margin: 16px 0;
  }

  @media (max-width: 767px) {
    font-size: 30px;
  }
`

const Description = styled.div`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  color: #ffffff;
  margin-bottom: 60px;
  max-width: 540px;

  @media (max-height: 830px) {
    font-size: 16px;
    margin-bottom: 30px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
`

const Link = styled.a`
  font-family: "Gilroy", sans-serif;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  svg {
    margin-left: 30px;
    transition: 0.1s ease-in-out;
  }

  &:hover {
    > svg {
      transform: translateX(10px);
    }
  }

  @media (max-width: 1199px) {
    margin-top: 25px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
`

const ImgContainer = styled.div`
  height: 50vh;
`;
