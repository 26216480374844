import styled from "astroturf"
import React, { useRef, useState } from "react"
import playIcon from "../../../images/activity/playIcon.svg"
import pauseIcon from "../../../images/pause.svg"
import fulscreenIcon from "../../../images/fulscreen.svg"
import fulscreenCollapseIcon from "../../../images/fulscreenCollapse.svg"

export const VideoBlock = ({video, builtIn = false, children}) => {
  const [play, setPlay] = useState(false)
  const [start, setStart] = useState(true)
  const [fullSize, setFullSize] = useState(false)

  const videoRef = useRef()
  const onStart = () => {
    if (videoRef.current) {
      videoRef.current.play()
      setPlay(true)
      setStart(false)
    }
  }

  const onPause = () => {
    if (videoRef.current) {
      videoRef.current.pause()
      setPlay(false)
    }
  }

  const onClickVideo = () => {
    if (play) {
      onPause()
    } else {
      onStart()
    }
  }

  const onClickFullscreen = () => {
    setFullSize(prev => !prev)
    setTimeout(() => {
      videoRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      })
    }, 50)
  }

  return (
    <Container>
      {!play && start && (
        <PlayContainer onClick={onClickVideo}>
          <Play src={playIcon} alt={"play"} />
        </PlayContainer>
      )}
      {!start && (
        <PlayContainer onClick={onClickVideo} as="a">
          {play ? (
            <Play src={pauseIcon} />
          ) : (
            <Play src={playIcon} alt={"play"} />
          )}
        </PlayContainer>
      )}
      {!play && start && <StubImage builtIn={builtIn}>{children}</StubImage>}
      {!start && !builtIn && (
        <FullscreenIconContainer onClick={onClickFullscreen}>
          <FullscreenIcon
            src={!fullSize ? fulscreenIcon : fulscreenCollapseIcon}
          />
        </FullscreenIconContainer>
      )}
      <Video
        ref={videoRef}
        onClick={onClickVideo}
        fullSize={fullSize}
        builtIn={builtIn}
      >
        <source type="video/mp4" src={video} />
      </Video>
    </Container>
  )
}

const Container = styled.div`
  position: relative;

  & > a {
    display: none;
  }

  &:hover > a {
    display: flex;
  }

  overflow: hidden;
`

const StubImage = styled.div`
  position: absolute;
  top: 0;
  height: 50vh;
  object-fit: cover;
  cursor: pointer;
  z-index: 1;
  width: 100%;

  :global(.gatsby-image-wrapper){
    position: unset !important;
  }
  //@media screen and (max-width: 1199px) {
  //  height: 280px;
  //}
  //
  //&.builtIn {
  //  height: 750px !important;
  //  @media screen and (max-width: 2000px){
  //    height: 442px !important;
  //  }
  //  @media screen and (max-width: 1199px) {
  //    height: 100% !important;
  //    max-height: 280px;
  //  }
  //}
`

const Video = styled.video`
  width: 100%;
  height: 50vh;
  object-fit: cover;
  //@media screen and (max-width: 1199px) {
  //  height: 280px;
  //}

  //&.fullSize {
  //  width: 100%;
  //  height: auto;
  //  @media screen and (max-width: 1199px) {
  //    height: auto;
  //  }
  //}
  //
  //&.builtIn {
  //  height: 833px !important;
  //  @media screen and (max-width: 2000px){
  //    height: 442px !important;
  //  }
  //  @media screen and (max-width: 1199px) {
  //  
  //    height: auto !important;
  //    max-height: 280px;
  //  }
  //
  //  @media screen and (max-width: 420px) {
  //    height: 360px !important;
  //  }
  //}
`

const PlayContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: pointer;
  user-select: false;
`

const Play = styled.img`
  width: 80px;
  height: 80px;
  user-select: false;
  
  @media screen and (max-width: 420px){
    width: 70px;
    height: 70px;
  }
`

const FullscreenIconContainer = styled.a`
  padding: 24px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
  &:hover {
    opacity: 0.5;
  }
  cursor: pointer;
`

const FullscreenIcon = styled.img`
  width: 24px;
  height: 24px;
`
