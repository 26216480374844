import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export const GatsbyITCommunityBackgroundVideoImg = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "activity/iTCommunityBackgroundVideoImg.jpg" }) {
        id
        childImageSharp {
          fixed(width: 1440, height: 480, quality:70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return <Img fluid={data.file.childImageSharp.fixed} alt="IT-сообщество" style={{ width: "100%", height: "100%" }} />
}
